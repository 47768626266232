import { ApiPath } from "../config/http/api-path";
import { BaseResponse } from "../config/http/base_response";
import { HttpBuilder } from "../config/http/http_builder";
import { CardModel } from "../models/card-model";
import { OrderCreateModel } from "../models/order-create-model";
import { OrderModel } from "../models/order-model";
import { OrderRequestModel } from "../models/order-request-model";
import { TransactionModel } from "../models/transaction-model";

export class OrderRepository {
    createPayment(body: OrderCreateModel): Promise<BaseResponse<TransactionModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.order)
            .setBody(body)
            .setFromJson(TransactionModel.fromJson)
            .post<TransactionModel>()
    }

    getOrders(query: OrderRequestModel): Promise<BaseResponse<Array<OrderModel>>> {
        return HttpBuilder.main()
            .setPath(ApiPath.order)
            .setQuery({
                from: query.from?.toDateString(),
                to: query.to?.toDateString(),
                user_id: query.user_id
            })
            .setFromJson((json) => json.map(OrderModel.fromJson))
            .get<Array<OrderModel>>()
    }

    getCards(tranId?: string): Promise<BaseResponse<Array<CardModel>>> {
        return HttpBuilder.main()
            .setPath(ApiPath.cards)
            .setQuery({
                tranId
            })
            .setFromJson((json) => json.map(CardModel.fromJson))
            .get<Array<CardModel>>()
    }
    getOrderDetail(orderId?: string): Promise<BaseResponse<OrderModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.orderDetail)
            .setParam({
                id: orderId
            })
            .setFromJson(OrderModel.fromJson)
            .get()
    }
}