import { AccountCircle, Home, LockReset, LogoutRounded } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { RoutePath } from "../../router/route-path";
import { useRootProvider } from "../provider";
import { useUserManager } from "../../manager/user-manager";
import { useState } from "react";

interface ContentDrawerType {

}

const ContentDrawer = (props: ContentDrawerType) => {
    const navigate = useNavigate()
    const provider = useRootProvider()
    const userManager = useUserManager()
    const [openLogout, setOpenLogout] = useState(false)

    function _onPressedLogin(): void {
        provider.setOpen(false)
        navigate(RoutePath.login)
    }

    function _showUserInfo(): void {
        provider.setOpen(false)
        navigate(RoutePath.profile)
    }

    function _onPressedLogout() {
        setOpenLogout(false)
        provider.setOpen(false)
        userManager.createLocalUser()
        navigate(RoutePath.initial)
        provider.setToast('Đã đăng xuất')
    }
    function _onChangePassword() {
        provider.setOpen(false)
        navigate(RoutePath.changePassword)
    }
    function _onShowHistory() {
        provider.setOpen(false)
        navigate(RoutePath.orderHistory)
    }

    function _onHome() {
        provider.setOpen(false)
        navigate(RoutePath.initial)
    }

    return (
        <Box marginTop={4}>
            <ListItemButton
                onClick={_onHome}
                sx={{
                    paddingX: 6
                }}>
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <ListItemText primary='Trang chủ' />
            </ListItemButton>

            {
                !userManager.isUserLogin() &&
                <ListItemButton
                    onClick={_onPressedLogin}
                    sx={{
                        paddingX: 6
                    }}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary='Đăng nhập' />
                </ListItemButton>
            }
            <ListItemButton
                onClick={_onShowHistory}
                sx={{
                    paddingX: 6
                }}>
                <ListItemIcon>
                    <LockReset />
                </ListItemIcon>
                <ListItemText primary='Lịch sử mua hàng' />
            </ListItemButton>
            {
                userManager.isUserLogin() &&
                <Box>
                    <ListItemButton
                        onClick={_showUserInfo}
                        sx={{
                            paddingX: 6
                        }}>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        <ListItemText primary='Thông tin tài khoản' />
                    </ListItemButton>
                    <ListItemButton
                        onClick={_onChangePassword}
                        sx={{
                            paddingX: 6
                        }}>
                        <ListItemIcon>
                            <LockReset />
                        </ListItemIcon>
                        <ListItemText primary='Đổi mật khẩu' />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => setOpenLogout(true)}
                        sx={{
                            paddingX: 6
                        }}>
                        <ListItemIcon>
                            <LogoutRounded sx={{ color: 'red' }} />
                        </ListItemIcon>
                        <ListItemText primary='Đăng xuất' />
                    </ListItemButton>
                </Box>
            }
            <Dialog
                open={openLogout}
                onClose={() => setOpenLogout(false)}>
                <DialogTitle >
                    {"Thông báo"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography component={'span'}>Bạn có chắc chắn muốn đăng xuất?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenLogout(false)}>Hủy bỏ</Button>
                    <Button

                        onClick={_onPressedLogout}
                        autoFocus
                        variant="contained">
                        Đồng ý
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ContentDrawer