import { CategoryModel } from "./category-model"

export class CardModel {
    id?: string
    denomination?: number
    cardNumber?: string
    cardSeri?: string
    expired?: string
    type?: string
    category?: CategoryModel

    static fromJson(json: CardModel): CardModel {
        const data = Object.assign(new CardModel(), json)
        if(json.category){
            data.category = CategoryModel.fromJson(json.category)
        }
        return data
    }
    
}