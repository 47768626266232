import { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { useUserManager } from "../manager/user-manager";


const RootContext = createContext<RootType>(null!)

interface RootType {
    open: boolean
    setOpen: (open: boolean) => void
    toast: string | undefined
    setToast: (toast: string | undefined) => void
}

export function useRootProvider() {
    return useContext(RootContext)
}


export function RootProvider({ children }: { children: ReactElement }) {
    const userManager = useUserManager()
    const [open, setOpen] = useState<boolean>(false)
    const [toast, setToast] = useState<string | undefined>()

    useEffect(() => {
        if (!userManager.isUserLocalCreated()) {
            userManager.createLocalUser()
        }
    }, [])

    const value = {
        open,
        setOpen,
        toast,
        setToast,
    }
    return (
        <RootContext.Provider value={value}>
            {children}
        </RootContext.Provider>
    )
}