import { CategoryType } from "../enums/category-type"
import { EntityState } from "../enums/entity-state"
import { MenuItemType } from "../enums/menu-item-type"

export class CategoryModel {
    created?: string
    updated?: string
    deleted?: any
    id?: string
    name?: string
    image?: string
    level?: number
    categoryType?: CategoryType
    subType?:  MenuItemType
    idx?: number
    state?: EntityState

    static fromJson(json: CategoryModel): CategoryModel {
        const data = Object.assign(new CategoryModel(), json)
        return data
    }
}
