import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import { TabModel } from "../../../../models/tab-model";
import { CategoryModel } from "../../../../models/category-model";
import { MenuItemModel } from "../../../../models/menu-item-model";
import { OrderItemCreateModel } from "../../../../models/order-create-model";
import { PaymentMethodModel } from "../../../../models/payment-method-model";
import { useOrderManager } from "../../../../config/manager/order-manager";
import { MenuItemType } from "../../../../enums/menu-item-type";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../../config/router/route-path";
import { OrderRepository } from "../../../../repository/order-repository";
import { useRootProvider } from "../../../../config/layouts/provider";
import { useUserManager } from "../../../../config/manager/user-manager";


const CardOrderContext = createContext<CardOrderType>(null!)

interface CardOrderType {
    tab: TabModel | undefined
    setTab: (tab: TabModel | undefined) => void
    category: CategoryModel | undefined
    setCategory: (category: CategoryModel | undefined) => void
    mail: string | undefined
    setMail: (mail: string | undefined) => void
    error: string | undefined
    setError: (error: string | undefined) => void
    item: MenuItemModel | undefined
    setItem: (item: MenuItemModel | undefined) => void
    orderItems: any
    setOrderItems: (orderItems: any) => void
    paymentMethod: PaymentMethodModel | undefined
    setPaymentMethod: (paymentMethod: PaymentMethodModel | undefined) => void
    open: boolean
    setOpen: (open: boolean) => void
    isLoading: boolean
    setLoading: (isLoading: boolean) => void
    onUpdateQty: (qty: number, customItem?: MenuItemModel) => any
    updateEmail: (m: string) => any
    fetchOrderItems: () => any
    deleteOrderItem: (item?: OrderItemCreateModel) => any
    getTotalPrice: () => number
    getInitialValue: () => number
    onValidate: () => any
    onPayment: () => any
}

export function useCardOrderProvider() {
    return useContext(CardOrderContext)
}


export function CardOrderProvider({ children }: { children: ReactElement }) {
    const orderManager = useOrderManager()
    const userManager = useUserManager()
    const rootProvider = useRootProvider()
    const navigate = useNavigate()
    const [tab, setTab] = useState<TabModel | undefined>()
    const [category, setCategory] = useState<CategoryModel | undefined>()
    const [mail, setMail] = useState<string | undefined>('')
    const [error, setError] = useState<string | undefined>()
    const [item, setItem] = useState<MenuItemModel | undefined>()
    const [orderItems, setOrderItems] = useState<OrderItemCreateModel[]>([])
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodModel | undefined>()
    const [open, setOpen] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)

    function onUpdateQty(qty: number, customItem?: MenuItemModel) {
        const mainItem = customItem ?? item;
        if (mainItem?.id === null) return
        const order = orderManager.getOrder()
        const currentItem: OrderItemCreateModel | undefined = order.orderItems?.find((e) => e.id === mainItem?.id)
        if (!currentItem) {
            order.orderItems ??= []
            order.orderItems.push(OrderItemCreateModel.fromJson({
                id: mainItem?.id,
                image: category?.image,
                item: mainItem,
                type: category?.subType,
                quantity: qty
            } as OrderItemCreateModel))
        } else {
            currentItem.quantity = qty
        }
        if (qty === 0) {
            order.orderItems = order.orderItems?.filter((e) => e.id !== mainItem?.id)
        }
        orderManager.updateOrder(order)
        fetchOrderItems()
    }
    function updateEmail(m: string) {
        setMail(m)
        const order = orderManager.getOrder()
        order.mailing = m
        orderManager.updateOrder(order)
    }


    function fetchOrderItems() {
        const order = orderManager.getOrder()
        setOrderItems(order.orderItems ?? [])
    }

    function deleteOrderItem(item?: OrderItemCreateModel) {
        const order = orderManager.getOrder()
        order.orderItems = order.orderItems?.filter((e) => e.id !== item?.id)

        orderManager.updateOrder(order)
        fetchOrderItems()
    }

    function getTotalPrice(): number {
        const order = orderManager.getOrder()
        let price = 0
        for (const item of order.orderItems ?? []) {
            price += item.getPrice()
        }
        return price
    }

    function getInitialValue(): number {
        const order = orderManager.getOrder()
        return order.orderItems?.find((e) => e.id === item?.id)?.quantity ?? 0
    }

    function onValidate() {
        if (!userManager.getUser().id) {
            userManager.createLocalUser()
        }
        const order = orderManager.getOrder()
        if (!order.orderItems || order.orderItems?.length === 0) {
            return rootProvider.setToast('Vui lòng thêm thẻ vào giỏ hàng')
        }
        if (!paymentMethod?.type) {
            return rootProvider.setToast('Vui lòng chọn hình thức thanh toán')
        }
        if (!mail) {
            return rootProvider.setToast('Vui lòng nhập email nhận thẻ')
        }
        if (!mail?.length) {
            return setError('Vui lòng nhập email nhận thẻ!')
        }
        const reg: RegExp = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        if (!reg.test(mail!)) {
            return setError('Email không đúng định dạng!')
        }
        setError(undefined)
        setOpen(true)
    }

    async function onPayment() {
        setLoading(true)
        const order = orderManager.getOrder()
        order.userId = userManager.getUser().id
        order.paymentMethod = paymentMethod?.type
        const response = await new OrderRepository().createPayment(order)
        setLoading(false)
        if (!response.isOk()) {
            return rootProvider.setToast(response.message)
        }
        order.orderItems = []
        setOrderItems(order.orderItems ?? [])
        orderManager.updateOrder(order)
        navigate(RoutePath.payment.replace(':tranId', response.data?.id ?? ''))
    }

    useEffect(() => {
        const order = orderManager.getOrder()
        setMail(order.mailing)
        setOrderItems(order.orderItems ?? [])
    }, [])

    const value = {
        tab,
        setTab,
        category,
        setCategory,
        mail,
        setMail,
        error,
        setError,
        item,
        setItem,
        orderItems,
        setOrderItems,
        paymentMethod,
        setPaymentMethod,
        open,
        setOpen,
        isLoading,
        setLoading,
        onUpdateQty,
        updateEmail,
        fetchOrderItems,
        deleteOrderItem,
        getTotalPrice,
        getInitialValue,
        onValidate,
        onPayment,
    }
    return (
        <CardOrderContext.Provider value={value}>
            {children}
        </CardOrderContext.Provider>
    )
}