import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoutePath } from './config/router/route-path';
import RootPage from './config/layouts/root-page';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { CardOrderProvider } from './pages/home/tabs/card-order/provider';
import { RootProvider } from './config/layouts/provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AuthProvider } from './config/layouts/auth/auth-provider';

const HomePage = lazy(() => import('./pages/home'))
const LoginPage = lazy(() => import('./pages/auth/login'))
const RegisterPage = lazy(() => import('./pages/auth/register'))
const ForgotPasswordPage = lazy(() => import('./pages/auth/forgot-pass'))
const ForgotSuccessPage = lazy(() => import('./pages/auth/forgot-pass/success'))
const ChangePasswordPage = lazy(() => import('./pages/auth/change-password'))
const ChangePasswordSuccessPage = lazy(() => import('./pages/auth/change-password/success'))
const OrderHistoryPage = lazy(() => import('./pages/order-history'))
const PaymentPage = lazy(() => import('./pages/payment'))
const PaymentSuccessPage = lazy(() => import('./pages/payment/success'))
const ArticlePage = lazy(() => import('./pages/article'))
const UserProfilePage = lazy(() => import('./pages/auth/user-profile'))

const theme = createTheme({
  typography: { fontFamily: 'Lexend' }
})
function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <RootProvider>
            <CardOrderProvider>
              <Routes>
                <Route element={<RootPage />}>
                  <Route path={RoutePath.initial} element={<Suspense><HomePage /></Suspense>} />
                  <Route path={RoutePath.login} element={<Suspense><LoginPage /></Suspense>} />
                  <Route path={RoutePath.register} element={<Suspense><RegisterPage /></Suspense>} />
                  <Route element={<AuthProvider />}>
                    <Route path={RoutePath.forgotPassword} element={<Suspense><ForgotPasswordPage /></Suspense>} />
                    <Route path={RoutePath.forgotPasswordSuccess} element={<Suspense><ForgotSuccessPage /></Suspense>} />
                    <Route path={RoutePath.changePassword} element={<Suspense><ChangePasswordPage /></Suspense>} />
                    <Route path={RoutePath.changePasswordSuccess} element={<Suspense><ChangePasswordSuccessPage /></Suspense>} />
                    <Route path={RoutePath.profile} element={<Suspense><UserProfilePage /></Suspense>} />
                  </Route>
                  <Route path={RoutePath.orderHistory} element={<Suspense><OrderHistoryPage /></Suspense>} />
                  <Route path={RoutePath.payment} element={<Suspense><PaymentPage /></Suspense>} />
                  <Route path={RoutePath.paymentSuccess} element={<Suspense><PaymentSuccessPage /></Suspense>} />
                  <Route path={RoutePath.article} element={<Suspense><ArticlePage /></Suspense>} />

                  <Route path='*' element={<Suspense><HomePage /></Suspense>} />
                </Route>
              </Routes>
            </CardOrderProvider>
          </RootProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
