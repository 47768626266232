import { MenuItemType } from "../enums/menu-item-type"
import { MenuItemModel } from "./menu-item-model"

export class OrderCreateModel {
    mailing?: string
    paymentMethod?: string
    userId?: string
    orderItems?: OrderItemCreateModel[]
    
    static fromJson(json: OrderCreateModel): OrderCreateModel {
        const data = Object.assign(new OrderCreateModel(), json)

        if (json.orderItems) {
            data.orderItems = json.orderItems.map(OrderItemCreateModel.fromJson)
        }
        return data
    }

}

export class OrderItemCreateModel {
    id?: string
    image?: string
    item?: MenuItemModel
    type?: MenuItemType
    quantity?: number

    getPrice(): number {
        return (this.item?.orderPrice ?? 0) * (this.quantity ?? 0)
    }
    static fromJson(json: OrderItemCreateModel): OrderItemCreateModel {
        const data = Object.assign(new OrderItemCreateModel(), json)

        if (json.item) {
            data.item = Object.assign(new OrderItemCreateModel(), json.item)
        }

        return data
    }


}