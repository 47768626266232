import { ReactNode, createContext, useContext } from "react";
import { Navigate, Outlet } from "react-router";
import { useUserManager } from "../../manager/user-manager";
import { RoutePath } from "../../router/route-path";


const AuthContext = createContext<AuthType>(null!)

interface AuthType {

}

export function useAuthProvider() {
    return useContext(AuthContext)
}


export function AuthProvider({ children }: { children?: ReactNode }) {
    const userManager = useUserManager()



    const value = {

    }
    return (
        <AuthContext.Provider value={value}>
            {
                !userManager.isUserLogin()
                    ? <Navigate to={RoutePath.initial} />
                    : <Outlet />
            }
        </AuthContext.Provider>
    )
}