import axios, { HttpStatusCode, Method } from "axios";
import { objectToCamelCase } from "./utils";
import { BaseResponse } from "./base_response";
import { Builder, JsonDeserialize } from "./builder";
import { Constants } from "../constant/constants";
import { v4 as uuidV4 } from "uuid";


export class HttpBuilder implements Builder {


    private url?: string
    private path?: string
    private query?: object
    private param?: any
    private headers?: object
    private body?: any
    private fromJson?: any

    HttpBuilder() { }

    static main(): HttpBuilder {
        const mainBuilder = new HttpBuilder()
        console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);

        mainBuilder.setBaseUrl(process.env.REACT_APP_BASE_URL ?? '')
        return mainBuilder
    }

    setHeaders(headers?: object | undefined): Builder {
        this.headers = headers
        return this
    }

    setBaseUrl(url: string): Builder {
        this.url = url
        return this
    }
    setParam(param: object): Builder {
        this.param = param
        return this
    }
    setPath(path: string): Builder {
        this.path = path
        return this
    }
    setQuery(query: object): Builder {
        this.query = query
        return this
    }
    setBody(body: any): Builder {
        this.body = body
        return this
    }

    setFromJson<T>(fromJson?: JsonDeserialize<T>): Builder {
        this.fromJson = fromJson
        return this
    }

    private buildUrl(): string {
        this.url ??= 'http://localhost:5001'
        for (const key in this.param) {
            if (this.param[key]) {
                this.path = this.path?.replaceAll(`{${key}}`, this.param[key])
            }
        }
        return this.path ?? ''
    }

    private getHeaders(): object {
        const user = JSON.parse(localStorage.getItem(Constants.user) ?? "{}")
        return {
            ...this.headers,
            user: user?.id,
            token: user?.token
        }
    }

    async get<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('GET')
    }

    async post<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('POST')
    }

    async put<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('PUT')
    }

    async delete<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('DELETE')
    }

    async patch<T>(): Promise<BaseResponse<T>> {
        return this._perform<T>('PATCH')
    }

    private async _perform<T>(method: Method) {
        try {

            axios.interceptors.response.use((response) => {
                response.data = objectToCamelCase(response.data)
                const code = response.data?.code ?? response.data?.statusCode
                if (code === HttpStatusCode.Forbidden) {
                    localStorage.removeItem(Constants.user)
                    localStorage.setItem(Constants.user, JSON.stringify({ id: uuidV4() }))
                }
                return response;
            }, (error) => {
                error.response.data = objectToCamelCase(error.response.data)
                const code = error.response.data?.code ?? error.response.data?.statusCode
                if (code === HttpStatusCode.Forbidden) {
                    localStorage.removeItem(Constants.user)
                    localStorage.setItem(Constants.user, JSON.stringify({ id: uuidV4() }))
                }
                return error.response
            });
            const response = await axios({
                method: method,
                baseURL: this.url,
                url: this.buildUrl(),
                data: this.body,
                params: this.query,
                withCredentials: false,
                headers: this.getHeaders(),
            })
            return new BaseResponse<T>(
                response.headers,
                this.fromJson && response.data?.data ? this.fromJson(response.data?.data) : response.data?.data,
                response.data?.code ?? response.data?.statusCode,
                response.data?.message,
            )
        } catch (e) {
            console.log(e);

            return new BaseResponse<T>(
                undefined,
                undefined,
                99,
                'Client Exception',
            )
        }

    }
}


