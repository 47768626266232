import { TransactionStatus } from "../enums/transaction-status"
import { OrderModel } from "./order-model"

export class TransactionModel {
    paymentMethod?: string
    status?: TransactionStatus
    totalPrice?: number
    refId?: string
    extras?: ExtrasModel
    order?: OrderModel
    deleted?: string
    paidAt?: string
    created?: string
    updated?: string
    id?: string

    static fromJson(json: TransactionModel): TransactionModel {
        const data = Object.assign(new TransactionModel(), json)
        if (json.extras) {
            data.extras = ExtrasModel.fromJson(json.extras)
        }
        if (json.order) {
            data.order = OrderModel.fromJson(json.order)
        }
        return data
    }
}

export class ExtrasModel {
    accountNo?: string
    accountName?: string
    bankName?: string
    acqId?: number
    addInfo?: string
    amount?: number
    qrCode?: string
    
    static fromJson(json: ExtrasModel): ExtrasModel {
        const data = Object.assign(new ExtrasModel(), json)
        return data
    }
}