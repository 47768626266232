export class UserModel {
    created?: string
    updated?: string
    deleted?: any
    id?: string
    name?: string
    email?: string
    password?: string
    oldPassword?: string
    phone?: any
    role?: string
    token?: string

    static fromJson(json: UserModel): UserModel {
        const data = Object.assign(new UserModel(), json)
        return data
    }
    
  }