import { OrderState } from "../enums/order-state"
import { CardModel } from "./card-model"
import { TransactionModel } from "./transaction-model"

export class OrderModel {
    created?: string
    updated?: string
    deleted?: any
    id?: string
    mailing?: string
    user_id?: string
    transaction_id?: any
    transaction?: TransactionModel
    state?: OrderState
    doneAt?: any
    totalPrice?: number
    totalQty?: number
    items?: Array<CardModel>

    getStateDisplay(): string {
        switch (this.state) {
            case OrderState.pending:
                return 'Đang chờ'
            case OrderState.done:
                return 'Hoàn thành'
        }
        return 'None'
    }

    static fromJson(json: OrderModel): OrderModel {
        const data = Object.assign(new OrderModel(), json)
        if (json.transaction) {
            data.transaction = TransactionModel.fromJson(json.transaction)
        }
        if (json.items) {
            data.items = json.items.filter(e => e.id).map(CardModel.fromJson)
        }
        return data
    }
}