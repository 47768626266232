export class RoutePath {
    static initial: string = '/'
    static login: string = '/login'
    static register: string = '/register'
    static profile: string = '/profile'
    static forgotPassword: string = '/forgot-password'
    static forgotPasswordSuccess: string = '/forgot-password/success'
    static changePassword: string = '/change-password'
    static changePasswordSuccess: string = '/change-password/success'
    static payment: string = '/payment/:tranId'
    static paymentSuccess: string = '/payment/:tranId/success'
    static orderHistory: string = 'order-history'
    static article: string = 'article/:slug'
}