import { ApiPath } from "../config/http/api-path";
import { BaseResponse } from "../config/http/base_response";
import { HttpBuilder } from "../config/http/http_builder";
import { UserModel } from "../models/user-model";

export class UserRepository {

    login(user: UserModel): Promise<BaseResponse<UserModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.login)
            .setBody({
                username: user.email,
                password: user.password
            }).post<UserModel>()
    }

    register(user: UserModel): Promise<BaseResponse<UserModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.register)
            .setBody({
                name: user.name,
                email: user.email,
                password: user.password
            }).post<UserModel>()
    }

    forgotPassword(user: UserModel): Promise<BaseResponse<UserModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.forgotPassword)
            .setBody({
                email: user.email,
            }).post<UserModel>()
    }

    changePassword(user: UserModel): Promise<BaseResponse<UserModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.changePassword)
            .setBody({
                password: user.password,
                oldPassword: user.oldPassword
            }).patch<UserModel>()
    }

    getProfile(): Promise<BaseResponse<UserModel>> {
        return HttpBuilder.main()
            .setPath(ApiPath.profile)
            .get<UserModel>()
    }
}