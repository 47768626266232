export class ApiPath {
    static readonly login = '/api/card/v1/user/login'
    static readonly logout = '/api/v1/user/logout'
    static readonly register = '/api/card/v1/user/register'
    static readonly forgotPassword = '/api/card/v1/user/forgot-password'
    static readonly changePassword = '/api/card/v1/user/change-password'
    static readonly profile = '/api/card/v1/user/profile'
    static readonly menuCategory = '/api/card/v1/menu-category'
    static readonly menuItem = '/api/card/v1/menu-item'
    static readonly paymentMethod = '/api/card/v1/payment-method'
    static readonly order = '/api/card/v1/order'
    static readonly orderDetail = '/api/card/v1/order/{id}'
    static readonly cards = '/api/card/v1/card-warehouse/by-tran'
    static readonly transaction = '/api/card/v1/transaction/{id}'
    static readonly transactionStatus = '/api/card/v1/transaction/{id}/status'
    static readonly qrGenerator = '/api/card/v1/qr-generator'
    static readonly article = '/api/card/v1/article/slug/{slug}'
}