import { OrderCreateModel } from "../../models/order-create-model"

export const useOrderManager = () => {

    const getOrder = (): OrderCreateModel => {
      return  OrderCreateModel.fromJson(JSON.parse(localStorage.getItem('order') ?? '{}'))
    }
    const updateOrder = (order: OrderCreateModel) => localStorage.setItem('order', JSON.stringify(order))

    return {
        getOrder,
        updateOrder,
    }
}