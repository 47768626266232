export function joinImage(path?: string) {
    return `${process.env.REACT_APP_BASE_URL}/api/v1/file-upload/` + path
}

export function currency(c?: number): string {
    return new Intl.NumberFormat().format(c ?? 0)
}
export function numberCompact(c?: number): string {
    return new Intl.NumberFormat(undefined, { compactDisplay: 'short', notation: 'compact' }).format(c ?? 0)
}

export function snakeToCamelCase(str: string) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}


export function objectToCamelCase(obj: any) {
    let rtn = obj
    if (!rtn) {
        return rtn
    } else if (typeof (obj) === 'object') {
        if (obj instanceof Array) {
            rtn = obj.map(objectToCamelCase)
        } else {
            rtn = {}
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase())
                    rtn[newKey] = objectToCamelCase(obj[key])
                }
            }
        }
    }
    return rtn

}

