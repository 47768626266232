import { v4 as uuidV4 } from "uuid";
import { UserModel } from "../../models/user-model"
import { Constants } from "../constant/constants";

export const useUserManager = () => {

    const getUser = (): UserModel => {
        return UserModel.fromJson(JSON.parse(localStorage.getItem(Constants.user) ?? '{}'))
    }

    const updateUser = (user: UserModel) => localStorage.setItem(Constants.user, JSON.stringify(user))

    const isUserLogin = () => {
        const user = getUser()
        return user.id && user.token
    }

    const isUserLocalCreated = (): boolean => {
        const user = getUser()
        return !!user.id
    }

    const createLocalUser = () => {
        updateUser({ id: uuidV4() })
    }

    return {
        getUser,
        updateUser,
        isUserLogin,
        createLocalUser,
        isUserLocalCreated,
    }
}