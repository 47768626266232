import { AccountCircle } from "@mui/icons-material";
import { Alert, AppBar, Box, Drawer, Link, Snackbar, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Outlet } from "react-router";
import Logo from '../../media/logo.png';
import { UserRepository } from "../../repository/user-repository";
import { useUserManager } from "../manager/user-manager";
import { RoutePath } from "../router/route-path";
import { useMyTheme } from "../theme/use-my-theme";
import { useRootProvider } from "./provider";
import ContentDrawer from "./widget/content-drawer";
interface RootPageType {

}

const RootPage = (props: RootPageType) => {
    const provider = useRootProvider()
    const userManager = useUserManager()
    const theme = useMyTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    
    async function fetchData(): Promise<void> {
        const result = await new UserRepository().getProfile()
        if (!result.isOk()) {
            userManager.createLocalUser()
            return;
        }
    }


    useEffect(() => {
        fetchData()
    }, [])
    return (

        <Box>

            <AppBar position="sticky">
                <Toolbar>
                    <Box>

                    </Box>
                    <Link href={RoutePath.initial} sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <img
                            src={Logo}
                            alt={process.env.REACT_APP_SYSTEM_NAME}
                            style={{
                                objectFit: 'contain',
                                maxHeight: isMobile ? 36 : 64,
                                // paddingTop: 8,
                                // paddingBottom: 8
                            }} />
                        <Typography sx={{
                            color: 'white',
                            fontSize: 32,
                            fontStyle: 'italic',
                            display: isMobile ? 'none' : 'block'
                        }}><b>NAPTHE247</b></Typography>
                    </Link>


                    <Box pl={2}>
                        <Marquee>
                            <Typography>
                                <b>Sau 15 phút Quý khách thanh toán thành công mà chưa nhận được thẻ, Quý khách vui lòng liên hệ CSKH</b>
                            </Typography>
                        </Marquee>
                    </Box>
                    <Box
                        onClick={() => provider.setOpen(true)}
                        display={'flex'}
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            color: 'white',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer'
                        }}>
                        <AccountCircle />

                        {
                            userManager.isUserLogin() && !isMobile && (
                                <Typography display={'block'} ml={1}>{userManager.getUser().name}</Typography>
                            )
                        }


                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                anchor={'right'}
                open={provider.open}
                onClose={() => provider.setOpen(false)}
            >
                <ContentDrawer />
            </Drawer>
            <Outlet />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    marginTop: 8
                }}
                open={provider.toast !== undefined}
                autoHideDuration={6000}
                onClose={() => provider.setToast(undefined)}>
                <Alert onClose={() => provider.setToast(undefined)}
                    severity="info"
                    sx={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                        color: 'white'
                    }}>
                    {provider.toast}
                </Alert>
            </Snackbar>
        </Box>

    );
}

export default RootPage